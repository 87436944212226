import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators as gameActionCreators } from '../../store/modules/Game';
import { actionCreators as leaderboardActionCreators } from '../../store/modules/Leaderboard';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';
import helper from '../../utils/helper';

const GameRoundList = props => {
  const {
    isGameMode, id, list, requestRoundList, switchRoundDisplayStyle, switchRoundFilterVisibility,
  } = props;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32} className="table-image"
          items={list.data.map(r => r.player)} currentItem={row.original.player} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'player.name',
      Header: 'Player',
      accessor: 'player.name',
      sortable: false,
    },
    {
      id: 'participant.name',
      Header: 'Profile',
      accessor: 'participant.name',
      sortable: false,
    },
    {
      id: 'rank',
      Header: 'Rank',
      accessor: 'rank',
      sortable: false,
      show: !isGameMode,
      maxWidth: 70,
    },
    {
      id: 'aggregate',
      Header: 'Value',
      accessor: 'aggregate',
      sortable: false,
      show: !isGameMode,
      maxWidth: 70,
    },
    {
      id: 'startedOn',
      Header: 'Started',
      accessor: 'startedOn',
      Cell: cell => date.long(cell.value),
      sortable: true,
      maxWidth: 165,
    },
    {
      id: 'endedOn',
      Header: 'Ended',
      accessor: 'endedOn',
      Cell: cell => (cell.value ? date.long(cell.value) : 'In progress'),
      sortable: true,
      maxWidth: 165,
    },
    {
      accessor: 'player.id',
      Cell: cell => (
        <LinkContainer to={`/player/${cell.value}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];
  return (
    <List title={`${isGameMode ? 'Game' : 'Leaderboard'} Rounds`}
      subtitle="Round is a single try of the game or leaderboard"
      requestList={(pageIndex, pageSize, sortBy, sortDescending, filters) => requestRoundList(id, pageIndex, pageSize, sortBy, sortDescending, filters)}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchRoundDisplayStyle}
      switchFilterVisibility={switchRoundFilterVisibility}>
      {list.data.map(round => (
        <div key={round.id}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data.map(r => r.player)} currentItem={round.player}
                className={round.player.isDisabled ? 'avatar lg disabled' : undefined} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{round.player.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                {
                  !isGameMode
                  && (
                    <div className="row">
                      <div className="col-xs-12">
                        <span className="text-underline">Rank</span>
                        {' : '}
                        <span>{round.rank}</span>
                        <br />
                      </div>
                    </div>
                  )
                }
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Started</span>
                    {' : '}
                    <span>{date.long(round.startedOn)}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Ended</span>
                    {' : '}
                    <span>{round.endedOn ? date.long(round.endedOn) : 'In progress'}</span>
                    <br/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Created</span>
                    {' : '}
                    <span>{date.long(round.createdOn)}</span>
                    <br/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Completed</span>
                    {' : '}
                    <span>{round.completedOn ? date.long(round.completedOn) : 'In progress'}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`/player/${round.player.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

GameRoundList.propTypes = {
  isGameMode: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  list: ListPropTypes.isRequired,
  requestRoundList: PropTypes.func.isRequired,
  switchRoundDisplayStyle: PropTypes.func.isRequired,
  switchRoundFilterVisibility: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => ({
    list: helper.gameResolver(ownProps, state.game.roundList, state.leaderboard.roundList),
    isGameMode: helper.gameResolver(ownProps, true, false),
  }),
  (dispatch, ownProps) => bindActionCreators(helper.gameResolver(ownProps, gameActionCreators, leaderboardActionCreators), dispatch),
)(GameRoundList);
