import { saveAs } from 'file-saver';
import { DataType } from '../../utils/enum';
import Base from './Base';
import restHttp from '../../utils/restHttp';
import { actionCreators as Alert } from './Alert';

const base = new Base('player', 'Player');

const initialState = {
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      }, {
        name: 'Group',
        column: 'PlayerGroups.GroupId',
        type: DataType.Number,
        operator: 'Any',
        value: null,
        dataSourceKey: 'group',
      }, {
        name: 'Reward',
        column: 'Transactions.TransactionRewards.RewardId',
        type: DataType.Number,
        operator: 'AnyAny',
        value: null,
        dataSourceKey: 'reward',
      }],
    },
  },
  gameRoundList: {
    data: [],
    paging: { sortBy: 'startedOn', sortDescending: true },
    preferTable: true,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Game',
        column: 'GameId',
        type: DataType.Number,
        operator: '=',
        value: null,
        dataSourceKey: 'game',
      }, {
        name: 'Completed',
        column: 'CompletedOn',
        type: DataType.Boolean,
        operator: 'NotNull',
        value: null,
      },{
        name: 'Started',
        column: 'StartedOn',
        type: DataType.DateOnly,
        operator: 'DayIs',
        value: null,
      },],
    },
  },
  leaderboardRoundList: {
    data: [],
    paging: { sortBy: 'startedOn', sortDescending: true },
    preferTable: true,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Leaderboard',
        column: 'GameId',
        type: DataType.Number,
        operator: '=',
        value: null,
        dataSourceKey: 'leaderboard',
      }, {
        name: 'Completed',
        column: 'CompletedOn',
        type: DataType.Boolean,
        operator: 'NotNull',
        value: null,
      },{
        name: 'Started',
        column: 'StartedOn',
        type: DataType.DateOnly,
        operator: 'DayIs',
        value: null,
      },],
    },
  },
  rewardList: {
    data: [],
    paging: { sortBy: 'round.completedOn', sortDescending: true },
    preferTable: true,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Reward',
        column: 'Reward.Id',
        type: DataType.Number,
        operator: '=',
        value: null,
        dataSourceKey: 'reward',
      }, {
        name: 'Game',
        column: 'Round.GameId',
        type: DataType.Number,
        operator: '=',
        value: null,
        dataSourceKey: 'game',
      }],
    },
  },
  playerList: {
    data: [],
    paging: { sortBy: 'player.name', sortDescending: false },
    preferTable: true,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Group',
        column: 'Group.Id',
        type: DataType.Number,
        operator: '=',
        value: null,
        dataSourceKey: 'group',
      }, {
        name: 'Player',
        column: 'Player.Name',
        type: DataType.Text,
        operator: 'Contains',
        value: null,
      }],
    },
  },
  mappingList: {
    data: [],
    paging: { sortBy: 'startOn', sortDescending: true },
    preferTable: true,
  },
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
  requestReset: id => dispatch => {
    dispatch({ type: base.customRequestType('reset') });

    restHttp
      .put(`${base.name}/${id}/reset`)
      .then(response => {
        dispatch({
          type: base.customReceiveType('reset'),
          current: response.data,
        });
        dispatch(Alert.addSuccess(`${base.friendlyName} reset!`));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestGameRoundList: (id, pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
    dispatch({ type: base.customRequestType('game/round/list') });

    restHttp
      .get(`${base.name}/${id}/game/round`, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
      .then(response => {
        dispatch({
          type: base.customReceiveType('game/round/list'),
          data: response.data,
          paging: restHttp.pagingHeader(response),
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestLeaderboardRoundList: (id, pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
    dispatch({ type: base.customRequestType('leaderboard/round/list') });

    restHttp
      .get(`${base.name}/${id}/leaderboard/round`, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
      .then(response => {
        dispatch({
          type: base.customReceiveType('leaderboard/round/list'),
          data: response.data,
          paging: restHttp.pagingHeader(response),
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  switchGameRoundDisplayStyle: () => dispatch => {
    dispatch({ type: base.customType('game/round/display/switch') });
  },
  switchGameRoundFilterVisibility: () => dispatch => {
    dispatch({ type: base.customType('game/round/filter/switch') });
  },
  switchLeaderboardRoundDisplayStyle: () => dispatch => {
    dispatch({ type: base.customType('leaderboard/round/display/switch') });
  },
  switchLeaderboardRoundFilterVisibility: () => dispatch => {
    dispatch({ type: base.customType('leaderboard/round/filter/switch') });
  },
  requestRewardList: (id, pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
    dispatch({ type: base.customRequestType('reward/list') });

    restHttp
      .get(`${base.name}/${id}/reward`, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
      .then(response => {
        dispatch({
          type: base.customReceiveType('reward/list'),
          data: response.data,
          paging: restHttp.pagingHeader(response),
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestExport: () => dispatch => {
    dispatch({ type: base.customRequestType('export') });

    restHttp
      .get('player/export')
      .then(response => {
        dispatch({
          type: base.customReceiveType('export'),
        });
        const blob = base.dataURItoBlob("data:application/vnd.ms-excel;base64," + response.data);
        saveAs(blob, "players.xlsx");
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  switchRewardDisplayStyle: () => dispatch => {
    dispatch({ type: base.customType('reward/display/switch') });
  },
  switchRewardFilterVisibility: () => dispatch => {
    dispatch({ type: base.customType('reward/filter/switch') });
  },
  requestPlayerList: (id, pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
    dispatch({ type: base.customRequestType('player/list') });

    restHttp
      .get(`${base.name}/${id}/player`, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
      .then(response => {
        dispatch({
          type: base.customReceiveType('player/list'),
          data: response.data,
          paging: restHttp.pagingHeader(response),
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  switchPlayerDisplayStyle: () => dispatch => {
    dispatch({ type: base.customType('player/display/switch') });
  },
  switchPlayerFilterVisibility: () => dispatch => {
    dispatch({ type: base.customType('player/filter/switch') });
  },
  requestMappingList: (id, pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
    dispatch({ type: base.customRequestType('mapping/list') });

    restHttp
      .get(`${base.name}/${id}/mapping`, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
      .then(response => {
        dispatch({
          type: base.customReceiveType('mapping/list'),
          data: response.data,
          paging: restHttp.pagingHeader(response),
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestResetMany: (id, names, cb) => dispatch => {
    dispatch({ type: base.customRequestType('reset/many') });

    restHttp
      .put(`${base.name}/reset/tenant/${id}`, names)
      .then(response => {
        dispatch({
          type: base.customReceiveType('reset/many'),
          data: response.data,
        });
        if (response.data.length > 0) {
          dispatch(Alert.addSuccess(`${base.friendlyName}s reset with ${response.data.length} errors!`));
        } else {
          dispatch(Alert.addSuccess(`${base.friendlyName}s reset!`));
        }
        cb(response.data);
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestDeleteMany: (id, names, cb) => dispatch => {
    dispatch({ type: base.customRequestType('delete/many') });

    restHttp
      .put(`${base.name}/delete/tenant/${id}`, names)
      .then(response => {
        dispatch({
          type: base.customReceiveType('delete/many'),
          data: response.data,
        });
        if (response.data.length > 0) {
          dispatch(Alert.addSuccess(`${base.friendlyName}s deleted with ${response.data.length} errors!`));
        } else {
          dispatch(Alert.addSuccess(`${base.friendlyName}s deleted!`));
        }
        cb(response.data);
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  hideChildFilters: () => dispatch => {
    dispatch({ type: base.customType('child/filter/hide') });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (action.type === base.customReceiveType('reset')) {
    return {
      ...newState,
      details: {
        ...newState.details,
        [action.current.id]: action.current,
      },
    };
  }

  if (action.type === base.customReceiveType('game/round/list')) {
    return {
      ...state,
      gameRoundList: {
        ...newState.gameRoundList,
        data: action.data,
        paging: action.paging,
        filters: {
          ...newState.gameRoundList.filters,
        },
      },
    };
  }

  if (action.type === base.customReceiveType('leaderboard/round/list')) {
    return {
      ...newState,
      leaderboardRoundList: {
        ...newState.leaderboardRoundList,
        data: action.data,
        paging: action.paging,
        filters: {
          ...newState.leaderboardRoundList.filters,
        },
      },
    };
  }

  if (action.type === base.customType('game/round/display/switch')) {
    return {
      ...newState,
      gameRoundList: {
        ...newState.gameRoundList,
        preferTable: !newState.gameRoundList.preferTable,
      },
    };
  }

  if (action.type === base.customType('game/round/filter/switch')) {
    return {
      ...newState,
      gameRoundList: {
        ...newState.gameRoundList,
        filters: {
          ...newState.gameRoundList.filters,
          isVisible: !newState.gameRoundList.filters.isVisible,
        },
      },
    };
  }

  if (action.type === base.customType('leaderboard/round/display/switch')) {
    return {
      ...newState,
      leaderboardRoundList: {
        ...newState.leaderboardRoundList,
        preferTable: !newState.leaderboardRoundList.preferTable,
      },
    };
  }

  if (action.type === base.customType('leaderboard/round/filter/switch')) {
    return {
      ...newState,
      leaderboardRoundList: {
        ...newState.leaderboardRoundList,
        filters: {
          ...newState.leaderboardRoundList.filters,
          isVisible: !newState.leaderboardRoundList.filters.isVisible,
        },
      },
    };
  }

  if (action.type === base.customReceiveType('reward/list')) {
    return {
      ...newState,
      rewardList: {
        ...newState.rewardList,
        data: action.data,
        paging: action.paging,
        filters: {
          ...newState.rewardList.filters,
        },
      },
    };
  }

  if (action.type === base.customType('reward/display/switch')) {
    return {
      ...newState,
      rewardList: {
        ...newState.rewardList,
        preferTable: !newState.rewardList.preferTable,
      },
    };
  }

  if (action.type === base.customType('reward/filter/switch')) {
    return {
      ...newState,
      rewardList: {
        ...newState.rewardList,
        filters: {
          ...newState.rewardList.filters,
          isVisible: !newState.rewardList.filters.isVisible,
        },
      },
    };
  }

  if (action.type === base.customReceiveType('player/list')) {
    return {
      ...newState,
      playerList: {
        ...newState.playerList,
        data: action.data,
        paging: action.paging,
        filters: {
          ...newState.playerList.filters,
        },
      },
    };
  }

  if (action.type === base.customType('player/display/switch')) {
    return {
      ...newState,
      playerList: {
        ...newState.playerList,
        preferTable: !newState.playerList.preferTable,
      },
    };
  }

  if (action.type === base.customType('player/filter/switch')) {
    return {
      ...newState,
      playerList: {
        ...newState.playerList,
        filters: {
          ...newState.playerList.filters,
          isVisible: !newState.playerList.filters.isVisible,
        },
      },
    };
  }

  if (action.type === base.customReceiveType('mapping/list')) {
    return {
      ...newState,
      mappingList: {
        ...newState.mappingList,
        data: action.data,
        paging: action.paging,
        filters: {
          ...newState.mappingList.filters,
        },
      },
    };
  }

  if (action.type === base.customType('child/filter/hide')) {
    return {
      ...newState,
      rewardList: {
        ...initialState.rewardList,
      },
      leaderboardRoundList: {
        ...initialState.leaderboardRoundList,
      },
      gameRoundList: {
        ...initialState.gameRoundList,
      },
    };
  }

  return base.reducer(newState, action);
};
